<template>
  <div>
    <div
      @click="openSelectMember"
      :style="{ height: need_wechat ? '206px' : '166px' }"
      style="width: 290px; border-radius: 10px; border: 1px solid rgb(0, 153, 255);position:relative;"
    >
      <div
        v-if="selectMember && selectMember.uid && selectMember.uid.length"
        style="padding: 10px; "
      >
        <div style="text-align: center; font-weight: bold">
          {{ title }}
        </div>
        <div style="display: flex; flex-direction: row; align-items: center">
          <div>
            <avatar
              :avatar="selectMember.avatar"
              style="width: 60px; height: 60px; "
            ></avatar>
          </div>

          <div style="margin-left: 10px">
            <div>UID: {{ selectMember.uid }}</div>
            <div>名称: {{ selectMember.name }}</div>
            <div v-if="is_sect != 0">
              职位: {{ SECT_JOB_MAP[selectMember.job] }}
            </div>
            <!-- 有wechat的时候 -->
            <div v-if="need_wechat">
              <el-link
                href="javascript:void(0)"
                @click.native.stop="changeWechat"
                ><div
                  style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 200px"
                >
                  微信号: {{ selectMember.wechat }}
                </div></el-link
              >
            </div>
            <!-- 在match_wpl2023的时候，s4也是这个 -->  
            <div v-if="isGetWechat">
              <el-link href="javascript:void(0)"
                ><div
                  style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 200px"
                >
                  微信号:<span v-if="selectMember.wechat != ''">{{
                    selectMember.wechat
                  }}</span
                  ><span v-else>在小程序关联微信号</span>
                </div></el-link
              >
            </div>
          </div>
        </div>

        <div style="position: absolute; right: 10px; bottom: 5px">
          <el-link href="javascript:void(0)" @click.native.stop="remove"
            >删除</el-link
          >
        </div>
      </div>
      <div
        v-else
        style="display: flex; flex-direction: row; justify-content: center; align-items: center; color: rgb(0, 153, 255); width: 100%; height: 100%; text-align: center"
      >
        <slot></slot>
      </div>
    </div>
    <!-- 选择框 -->
    <el-dialog
      :title="'选择'"
      :visible.sync="memberVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      @closed="onClosed"
      width="500px"
    >
      <div style="max-height: 600px; overflow-y: auto">
        <el-form ref="sectForm" :model="memberParams" label-width="0">
          <el-form-item prop="searchKey">
            <el-input
              v-model="memberParams.searchKey"
              placeholder="根据UID/名称查询"
              @input="remoteMethod(memberParams.searchKey)"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                @click="remoteMethod(memberParams.searchKey)"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item label="">
            <div
              v-for="(item, index) in memberList"
              :key="`mem${index}`"
              @click="selectMemberHandler(item)"
              style="margin-bottom: 10px;"
            >
              <div
                style="border: 1px #aaaaaa solid; border-radius: 5px; padding: 10px; cursor: pointer"
              >
                <div
                  style="display: flex; flex-direction: row; align-items: center"
                >
                  <avatar
                    :avatar="item.avatar"
                    style="width: 40px; height: 40px; "
                  ></avatar>
                  <div
                    style="display: flex; flex-direction: column; flex: fit-content; margin-left: 10px; margin-right: 10px"
                  >
                    <div>昵称：{{ item.name }}</div>
                    <div>UID：{{ item.uid }}</div>
                  </div>
                  <div
                    v-if="item.is_black"
                    style="width: 40px; height: 24px; line-height: 24px; text-align: center; color: #ffffff; font-size: 12px"
                  >
                    <div style="border-radius: 5px; background-color: gray">
                      禁赛
                    </div>
                  </div>
                  <div
                    v-if="item.team_job"
                    style="width: 40px; height: 24px; line-height: 24px; text-align: center; color: #ffffff; margin-left: 10px; font-size: 12px"
                  >
                    <div style="border-radius: 5px; background-color: #FF9933">
                      {{ item.team_job }}
                    </div>
                  </div>
                  <div
                    v-if="is_sect != 0"
                    style="border-radius: 5px; background-color: #936949; width: 40px; height: 24px; line-height: 24px; font-size: 12px; text-align: center; color: #ffffff; margin-left: 10px"
                  >
                    {{ SECT_JOB_MAP[item.job] }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!memberList || memberList.length == 0">
              <div style="color: #777777; text-align: center; font-size: 13px">
                无数据
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      :title="'设置微信号'"
      :visible.sync="wechatVisible"
      v-if="wechatVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      @closed="onClosed"
      width="500px"
    >
      <div style="max-height: 400px; overflow-y: auto">
        <el-form
          ref="wechatForm"
          :model="wechatData"
          label-position="right"
          label-width="100px"
        >
          <el-form-item label="微信号" label-position="right" prop="wechat">
            <el-input
              type="text"
              v-model="wechatData.wechat"
              placeholder="请输入微信号"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveWechat">确定</el-button>
            <el-button @click="wechatVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import avatar from "./avatar";
import Avatar from "../../armory/components/avatar";
export default {
  name: "memberSelectItem",
  components: { Avatar },
  emits: ["onSelect", "onRemove"],
  props: {
    member: {
      type: [Object, Boolean],
      required: true,
    },
    members: {
      type: Array,
    },
    team: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    title: {
      type: String,
    },
    blackList: {
      type: Array,
      required: true,
    },
    need_wechat: {
      type: [Array, Boolean],
      
    },
    type: {
      type: String,
      default: "leader",
    },
  },
  mounted() {
    if (this.member) {
      this.selectMember = this.member;
    }
  },
  watch: {
    members: {
      handler: function(v) {
        this.members = v;
      },
      immediate: true,
    },
    member: {
      handler: function(v) {
        this.selectMember = v;
        this.memberVisible = false;
      },
      immediate: true,
    },
    $route: {
      handler: function(val) {
        this.type_id = this.$route.query.type_id;
        this.is_sect = val.query.is_sect;
      },
      immediate: true,
    },
  },
  computed: {
    isGetWechat() {
      if (this.$route.query.match_type == "match_wpl2023") {
        return true;
      } else {
        return false;
      }
    },
    memberList: function() {
      let res = [];
      if (this.is_sect != 0) {
        if (!this.team.sect_id || !this.team.sect_id.length) {
          return res;
        }
      }
      let source = this.members;
      let blacks = this.blackList || [];
      let team_members = this.team.members;
      let team_leader_uid = this.team.leader.uid;
      let alternate = this.team.alternate;
      source.forEach((r) => {
        let team_job = false;
        if (team_members.find((d) => d.uid == r.uid)) {
          team_job = "选手";
        } else if (alternate.find((d) => d.uid == r.uid)) {
          team_job = "替补";
        } else if (team_leader_uid == r.uid) {
          team_job = "领队";
        }

        res.push({
          ...r,
          is_black: blacks.findIndex((d) => d == r.uid) >= 0,
          team_job: team_job,
        });
      });

      res = res.filter(
        (r) =>
          r.uid.indexOf(this.memberParams.searchKey) >= 0 ||
          r.name.indexOf(this.memberParams.searchKey) >= 0
      );
      return res;
    },
  },
  data() {
    return {
      is_sect: null,
      type_id: "",
      loading: false,
      selectMember: false,
      memberVisible: false,
      memberParams: {
        searchKey: "",
      },
      wechatVisible: false,
      wechatData: {
        wechat: "",
      },
      SECT_JOB_MAP: {
        "10": "掌门",
        "20": "副掌门",
        "30": "长老",
        "40": "成员",
        "50": "见习",
      },
      selectItem: false,
    };
  },
  methods: {
    async remoteMethod(query) {
      if (this.is_sect == 0) {
        if (query !== "" && query.length == 8) {
          let param = {
            lrs_uid: query,
          };

          let { data } = await this.$http.match.member_team_wdl(param);
          this.$emit("getMembers", data);
        }
      }
    },
    onClosed() {
      this.wechatData.wechat = "";
      this.memberParams.searchKey = "";
      if (this.is_sect == 0) {
        this.$emit("getMembers", []);
      }
    },
    openSelectMember() {
      if (this.is_sect != 0) {
        if (!this.team.sect_id || !this.team.sect_id.length) {
          this.$message.warning("请先选择门派");
          return;
        }
      }
      this.memberVisible = true;
      this.selectItem = false;
    },
    remove() {
      if (!this.selectMember) {
        return;
      }

      this.$emit("onRemove", { index: this.index, data: this.selectMember });
    },
    selectMemberHandler(item) {
      if (
        this.type == "leader" &&
        this.team.leader &&
        item.uid == this.team.leader.uid
      ) {
        this.memberVisible = false;
        return;
      }

      if (this.type == "member") {
        let existMember = this.team.members.find((r) => r.index == this.index);
        if (existMember && item.uid == existMember.uid) {
          this.memberVisible = false;
          return;
        }
      }

      if (this.type == "alternate") {
        let existMember = this.team.alternate.find(
          (r) => r.index == this.index
        );
        if (existMember && item.uid == existMember.uid) {
          this.memberVisible = false;
          return;
        }
      }

      if (item.is_black) {
        this.$message.error("该成员已被禁赛，请选择其他成员");
        return;
      }

      if (this.need_wechat) {
        this.wechatData.wechat = "";
        this.wechatVisible = true;
        this.memberVisible = false;
        this.selectItem = { index: this.index, data: item };
      } else {
        this.$emit("onSelect", { index: this.index, data: item });
      }

      if (this.is_sect == 0) {
        this.$emit("getMembers", []);
      }
    },
    saveWechat() {
      this.$refs.wechatForm.validate((valid) => {
        if (valid) {
          this.selectItem.data.wechat = this.wechatData.wechat;
          this.$emit("onSelect", this.selectItem);
          this.wechatData.wechat = "";
          this.wechatVisible = false;
        }
      });
    },
    changeWechat() {
      console.log(111);
      this.wechatData.wechat = this.selectMember.wechat;
      this.wechatVisible = true;
      this.selectItem = {
        index: this.selectMember.index,
        data: this.selectMember,
      };
    },
  },
};
</script>

<style lang ="less" scoped>
/deep/ .el-dialog__body {
  padding: 0 20px 30px 20px;
}
</style>
